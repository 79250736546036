import React from "react";
import { connect } from "react-redux";
import StepsNavigation from "./StepsNavigation";
import RegistrationSsn from "./Ssn/RegistrationSsn";
import RegistrationMobile from "./Mobile/RegistrationMobile";
import RegistrationSms from "./Sms/RegistrationSms";
import RegistrationConfirmUserInfo from "./ConfirmUserInfo/RegistrationConfirmUserInfo";
import { getCurrentStep } from "../../store/registration/selectors";

function Registration({ currentStep }) {
  let stepToRender;
  switch (currentStep) {
    case 1:
      stepToRender = <RegistrationSsn />;
      break;
    case 2:
      stepToRender = <RegistrationMobile />;
      break;
    case 3:
      stepToRender = <RegistrationSms />;
      break;
    case 4:
      stepToRender = <RegistrationConfirmUserInfo skipVerification="false" />;
      break;
    default:
      stepToRender = <RegistrationSsn />;
  }
  return (
    <>
      <StepsNavigation step={currentStep} />
      {stepToRender}
    </>
  );
}

function mapStateToProps(state) {
  return {
    currentStep: getCurrentStep(state)
  };
}

export default connect(mapStateToProps)(Registration);
