import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CurrentStep = styled.span`
  align-self: flex-end;
  font-size: larger;
  font-weight: bold;
  display: ${props => (props.step ? "inline" : "none")};
`;

const HeaderText = styled.p`
  order: 0;
  font-size: 1.5em;
  margin-top: 1em;
  margin-bottom: 0.5em;
`;

const Description = styled.p`
  order: 0;
  flex-basis: 100%;
  font-size: 1.2em;
  margin-top: 1em;
  margin-bottom: 2.5em;
`;

export function Header({ step, stepof, header, description }) {
  return (
    <HeaderContainer>
      <CurrentStep step={step}>{`${step}/${stepof}`}</CurrentStep>
      <HeaderText>{header}</HeaderText>
      <Description>{description}</Description>
    </HeaderContainer>
  );
}

Header.propTypes = {
  step: PropTypes.string,
  stepof: PropTypes.string,
  header: PropTypes.string.isRequired,
  description: PropTypes.string
};

CurrentStep.propTypes = {
  step: PropTypes.string
};
