import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { InputGroup, Input, InputLabel } from "../Shared";
import { setEmail } from "../../store/userInfo/actions";

function UserInfoEmailComponent({ setMail }) {
  return (
    <>
      <InputLabel>Veldu netfang</InputLabel>
      <InputGroup direction="row" width="100" marginBottom="2" marginRight="0">
        <Input
          width="100"
          placeholder="Netfang"
          marginRight="0"
          onChange={e => {
            setMail(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
}

const UserInfoEmail = connect(
  null,
  { setMail: setEmail }
)(UserInfoEmailComponent);

export default UserInfoEmail;

UserInfoEmailComponent.propTypes = {
  setMail: PropTypes.func.isRequired
};
