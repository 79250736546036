import React, { useState } from "react";
import {
  Input,
  InputLabel,
  InputGroup,
  Spinner,
  Button,
  ButtonGroup,
  Form,
  InputText
} from "../Shared";

function SmsForm({
  validateSMSMutation,
  validateSMSLoading,
  claim,
  maskedNumber
}) {
  const [verificationCode, setVerificationCode] = useState("");
  const [buttonActive, setButtonActive] = useState(false);
  const [inputError, setInputError] = useState({
    message: "Vinsamlegast sláðu inn fjögurra tölustafa kóða",
    show: false
  });

  function submitVerification(e) {
    e.preventDefault();
    if (!buttonActive) {
      return;
    }
    setButtonActive(false);
    validateSMSMutation({
      variables: { claim, verificationCode }
    }).catch(error => {
      error.graphQLErrors.forEach(({ extensions }) => {
        const { code } = extensions;
        switch (code) {
          case "NOT_FOUND":
            setInputError({
              message: "Kóði útrunninn, reyndu aftur",
              show: true
            });
            break;
          case "UNAUTHENTICATED":
            setInputError({
              message: "Rangur kóði, reyndu aftur",
              show: true
            });
            break;
          default:
            setInputError({
              message: "Óvænt villa, vinsamlegast reyndu aftur",
              show: true
            });
        }
        setButtonActive(true);
      });
    });
  }

  function handleInvalidInput(e) {
    e.preventDefault();
    setInputError({
      message: "Vinsamlegast sláðu inn fjögurra tölustafa kóða",
      show: true
    });
  }

  function handleChange(e) {
    e.preventDefault();
    setVerificationCode(e.target.value);
    setButtonActive(e.target.value.length === 4);
    setInputError({ show: false });
  }

  return (
    <Form onSubmit={e => submitVerification(e)}>
      <InputText>Sláðu inn 4-stafa SMS-kóða.</InputText>
      <InputLabel inputError={inputError.show}>
        {inputError.show
          ? inputError.message
          : `SMS-kóði sendur í símanúmer ${maskedNumber || ""}`}
      </InputLabel>
      <InputGroup width="100" direction="row">
        <Input
          onChange={e => handleChange(e)}
          onInvalid={e => handleInvalidInput(e)}
          width="50"
          type="code"
          minLength="4"
          maxLength="4"
          placeholder="Kóði"
        />
        <ButtonGroup>
          <Button
            active={buttonActive}
            marginRight="0"
            width="70"
            type="submit"
          >
            {!validateSMSLoading && "Auðkenna"}
            {validateSMSLoading && <Spinner button />}
          </Button>
        </ButtonGroup>
      </InputGroup>
    </Form>
  );
}

export default SmsForm;
