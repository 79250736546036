import {
  SET_VERIFIED_CLAIM,
  SET_SMS_REGISTRATION_CLAIM,
  SET_SMS_VERIFIED_CLAIM,
  SET_CURRENT_STEP
} from "./actions";

const initialStateRegistration = {
  currentStep: 1,
  verifiedClaim: "",
  smsRegistrationClaim: "",
  smsVerifiedClaim: ""
};

export default function registration(state = initialStateRegistration, action) {
  switch (action.type) {
    case SET_VERIFIED_CLAIM:
      return {
        ...state,
        verifiedClaim: action.claim
      };
    case SET_SMS_REGISTRATION_CLAIM:
      return {
        ...state,
        smsRegistrationClaim: action.claim
      };
    case SET_SMS_VERIFIED_CLAIM:
      return {
        ...state,
        smsVerifiedClaim: action.claim
      };
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.step
      };
    default:
      return state;
  }
}
