import React from "react";
import { Route, Redirect, withRouter, Switch } from "react-router-dom";
import routes from "../Shared/routes";
import PrivateRoutes from "./PrivateRoutes";
import { hasToken } from "../../utility";

function Routes() {
  return (
    <Switch>
      {routes.map(route => {
        if (!route.private) {
          return (
            <Route
              key={route.path}
              {...route.props}
              path={route.path}
              component={route.component}
            />
          );
        }
        return null;
      })}
      {hasToken() && <PrivateRoutes />}
      <Redirect to="/eid" />
    </Switch>
  );
}

export default withRouter(Routes);
