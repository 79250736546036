import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import SmsForm from "./SmsForm";
import GetUser from "../GetUser/GetUser";
import { setHideError } from "../../store/error/actions";

const VALIDATE_SMS_VERIFICATION_CODE = gql`
  mutation verifyLoginUsingPassword($claim: ID!, $verificationCode: String!) {
    verifyLoginUsingPassword(
      claim: $claim
      verificationCode: $verificationCode
    ) {
      claim
    }
  }
`;

function ValidateSMSComponent({ hideError, location, data: userPassData }) {
  return (
    <Mutation
      mutation={VALIDATE_SMS_VERIFICATION_CODE}
      onCompleted={() => {
        hideError();
      }}
    >
      {(verifyLoginUsingPassword, { data, loading }) => {
        if (data) {
          return (
            <GetUser
              claim={data.verifyLoginUsingPassword.claim}
              path={location.pathname}
            />
          );
        }
        return (
          <SmsForm
            validateSMSMutation={verifyLoginUsingPassword}
            validateSMSLoading={loading}
            claim={userPassData.loginUsingPassword.claim}
            maskedNumber={userPassData.loginUsingPassword.maskedPhoneNumber}
          />
        );
      }}
    </Mutation>
  );
}

const SmsMutation = withRouter(
  connect(
    null,
    { hideError: setHideError }
  )(ValidateSMSComponent)
);

export default SmsMutation;

ValidateSMSComponent.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line
  hideError: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired // eslint-disable-line
};
