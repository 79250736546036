import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Input,
  Button,
  Spinner,
  Form,
  InputText,
  InputLabel,
  InputGroup
} from "../Shared";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonActive: false,
      resource: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  submit(e) {
    e.preventDefault();
    const { mutation } = this.props;
    const { resource, buttonActive } = this.state;
    if (!buttonActive) {
      return;
    }
    mutation({ variables: { resource } });
  }

  handleChange(e) {
    this.setState({
      resource: e.target.value,
      buttonActive: e.target.value !== ""
    });
  }

  render() {
    const { buttonActive } = this.state;
    const { loading } = this.props;
    return (
      <Form>
        <InputText>Sláðu inn kennitölu tengda við mínar síður.</InputText>
        <InputLabel>Kennitala</InputLabel>
        <InputGroup direction="row" width="100">
          <Input
            placeholder="Kennitala"
            autoFocus
            width="50"
            margin="0"
            onChange={e => this.handleChange(e)}
          />
          <Button
            margin="auto"
            width="40"
            active={buttonActive}
            onClick={e => this.submit(e)}
          >
            {loading ? <Spinner /> : "Senda"}
          </Button>
        </InputGroup>
      </Form>
    );
  }
}

const ForgotPasswordForm = ForgotPassword;

export default ForgotPasswordForm;

ForgotPassword.propTypes = {
  mutation: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
