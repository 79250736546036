import React from "react";
import { render } from "react-dom";
import App from "./components/App";
import store from "./store/store";

const rootEl = document.getElementById("root");
render(<App store={store} />, rootEl);

if (module.hot) {
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(<NextApp store={store} />, rootEl);
  });
}
