import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import * as styles from "./styles";
import logo from "../../img/midlar_vodafone.png";
import eidLogo from "../../img/icon-eid.svg";
import userPassLogo from "../../img/icon-userpass.svg";
import Routes from "../Routes/Routes";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { DevelopmentHelper } from "../Shared/DevelopmentHelper";
import { getShowError } from "../../store/error/selectors";
import { setHideError } from "../../store/error/actions";

function LoginComponent({ hideError, showError, location }) {
  const errorMessage = showError ? <ErrorMessage /> : null;

  useEffect(() => {
    hideError();
  }, [location.pathname, hideError]);

  return (
    <styles.LoginContainer>
      {process.env.NODE_ENV === "development" ? <DevelopmentHelper /> : null}
      <styles.LogoSoup src={logo} />
      {(location.pathname === "/eid" || location.pathname === "/userpass") && (
        <>
          <styles.LoginHeader>
            Skráðu þig inn á
            <styles.LoginHeaderBolder>
              {" "}
              {sessionStorage.getItem("origin") || "Mínar síður"}
            </styles.LoginHeaderBolder>
          </styles.LoginHeader>
          <styles.LoginMechanismList>
            <styles.LoginMechanismListItem to="/eid">
              <styles.LoginChoiceLogo src={eidLogo} />
              <styles.LoginMechanismChoice>
                Rafræn skilríki
              </styles.LoginMechanismChoice>
            </styles.LoginMechanismListItem>
            <styles.LoginMechanismListItem to="/userpass">
              <styles.LoginChoiceLogo src={userPassLogo} />
              <styles.LoginMechanismChoice>
                Lykilorð
              </styles.LoginMechanismChoice>
            </styles.LoginMechanismListItem>
          </styles.LoginMechanismList>
        </>
      )}

      <styles.LoginFormContainer>
        {errorMessage}
        <Routes />
      </styles.LoginFormContainer>
      {location.pathname === "/userpass" && (
        <styles.RegistrationLink to="/registration">
          Nýskráning
        </styles.RegistrationLink>
      )}
    </styles.LoginContainer>
  );
}

const Login = withRouter(
  connect(
    state => ({
      showError: getShowError(state)
    }),
    { hideError: setHideError }
  )(LoginComponent)
);

export default Login;

LoginComponent.propTypes = {
  showError: PropTypes.bool.isRequired,
  hideError: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired // eslint-disable-line
};
