import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Input,
  InputLabel,
  InputGroup,
  Spinner,
  Button,
  ButtonGroup,
  Form,
  InputText,
  InputContainer,
  InputPasswordContainer,
  InputHideShowPassIcon
} from "../Shared";
import * as customStyles from "./styles";
import showPass from "../../img/show_password.png";
import hidePass from "../../img/hide_password.png";
import { removeToken } from "../../utility";
import { setShowError } from "../../store/error/actions";

function UserPassForm({ mutation, data, loading, showError }) {
  removeToken();
  const [buttonActive, setButtonActive] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState(
    "Vinsamlegast sláðu inn fjögurra tölustafa kóða"
  );
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  function showHidePassword(e) {
    e.preventDefault();
    setShowPassword(!showPassword);
  }

  function handleInvalidInput(e) {
    e.preventDefault();
    setInputError(true);
  }

  function handleChange(e) {
    e.preventDefault();
    if (e.target.name === "username") {
      setUsername(e.target.value);
    }
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  }

  function handleError(error) {
    if (!error.graphQLErrors || error.graphQLErrors.length <= 0) {
      showError();
    } else {
      error.graphQLErrors.forEach(({ extensions }) => {
        const { code } = extensions;
        switch (code) {
          case "UNAUTHENTICATED":
            showError("Kennitala eða lykilorð ekki rétt");
            break;
          default:
            showError();
        }
      });
    }
  }

  useEffect(() => {
    if (username.length === 10 && password.length >= 8) {
      setButtonActive(true);
    } else {
      setButtonActive(false);
    }
  }, [username, password]);

  function submitLogin(e) {
    e.preventDefault();
    if (!buttonActive) {
      return;
    }
    setButtonActive(false);
    mutation({
      variables: { username, password }
    }).catch(error => {
      handleError(error);
      setButtonActive(true);
    });
  }

  return (
    <Form onSubmit={e => submitLogin(e)}>
      <InputText>Sláðu inn kennitölu og lykilorð.</InputText>
      <InputContainer width="100">
        <InputGroup width="50" direction="column">
          <InputLabel>Kennitala</InputLabel>
          <Input
            name="username"
            onChange={e => handleChange(e)}
            minLength="10"
            maxLength="10"
            width="100"
            type="text"
            placeholder="Kennitala"
            autoFocus
          />
        </InputGroup>
        <InputGroup width="50" direction="column">
          <customStyles.LabelLinkContainer>
            <customStyles.Label>Lykilorð</customStyles.Label>
            <customStyles.ForgotPasswordLink to="/forgotpassword" tabIndex="-1">
              Gleymt?
            </customStyles.ForgotPasswordLink>
          </customStyles.LabelLinkContainer>
          <InputPasswordContainer width="100">
            <Input
              name="password"
              width="100"
              onChange={e => handleChange(e)}
              type={showPassword ? "text" : "password"}
              placeholder="Lykilorð"
            />
            <InputHideShowPassIcon
              src={showPassword ? hidePass : showPass}
              onClick={e => showHidePassword(e)}
            />
          </InputPasswordContainer>
        </InputGroup>
      </InputContainer>
      {!data && (
        <ButtonGroup>
          <Button active={buttonActive} marginRight="0" type="submit">
            {loading ? <Spinner button /> : "Fá SMS"}
          </Button>
        </ButtonGroup>
      )}
    </Form>
  );
}

export default connect(
  null,
  { showError: setShowError }
)(UserPassForm);
