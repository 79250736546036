import React from "react";
import styled from "styled-components";
import { Header } from "../../Shared";

const Container = styled.div`
  margin: 3em;
`;

function EDocSentConfirmation() {
  return (
    <Container>
      <Header
        header="Skjal hefur verið sent í heimabankann."
        description="Til að halda nýskráningu áfram þarf að finna skjalið undir rafrænum skjölum og smella á hlekkinn."
      />
    </Container>
  );
}

export default EDocSentConfirmation;
