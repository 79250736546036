const colors = {
  vodaRed: "#e60000",
  lightGrey: "#e2e2e2",
  darkGrey: "#b3b3b3",
  darkerGrey: "#303030",
  backgroundGrey: "#f5f5f5",
  white: "#fff"
};

export default colors;
