import styled from "styled-components";
import PropTypes from "prop-types";
import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Button } from "../Shared";
import colors from "../Shared/colors";

export const LoginHeaderBolder = styled.span`
  font-family: "Vodafone", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 40px;
  color: black !important;
`;

export const LoginHeader = styled.h1`
  font-family: "Vodafone", Helvetica, Arial, sans-serif;
  font-weight: lighter;
  font-size: 40px;
  color: black !important;
`;

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2em;
`;

export const LoginMechanismList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  list-style: none;
  margin: 3.4em 0 0 0;
  padding: 0;
  width: 50%;
  max-width: 650px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const LoginMechanismLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-content: center;
  width: 50%;
  max-width: 650px;
  padding-bottom: 2.75em;
  cursor: pointer;
  text-decoration: none;
  &.active {
    font-weight: bold;
    border-bottom: solid 1px ${colors.vodaRed};
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const LoginMechanismListItem = props => (
  <LoginMechanismLink {...props} />
);

export const LoginMechanismChoice = styled.span`
  font-size: 1.25em;
  color: black;
  cursor: pointer;
  text-decoration: none;
`;

export const LoginFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${colors.white};
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  width: 50%;
  min-height: 200px;
  max-width: 650px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const RegistrationLink = styled(Link)`
  margin-top: 2em;
  text-decoration: none;
  border-bottom: 1px solid black;
  font-family: "Vodafone", Helvetica, Arial, sans-serif;
  color: black !important;
  font-size: 20px;
  &:hover {
    cursor: pointer;
  }
`;

export const CurrentStep = styled.strong`
  float: right;
  font-size: larger;
`;

export const ChooseAccountForm = styled.form`
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
`;

export const ChooseAccountButton = styled(Button)`
  width: -webkit-fill-available;
  color: black;
  font-size: 1.5em;
  text-align: left;
  display: table-cell;
  vertical-align: middle;
  margin: ${props => (props.margin ? "0 0 1.35em 0" : "0")};
`;

export const LoginForm = styled.form`
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0;
  width: 100%;
`;

export const LoginInputLabel = styled.label`
  font-weight: bold;
  margin-bottom: 1em;
  display: block;
`;

export const LoginInputText = styled.p`
  order: 0;
  flex-basis: 100%;
  font-size: 1.3em;
  margin-top: 1em;
  margin-bottom: 2.5em;
`;

export const LoginInputGroup = styled.div`
  width: ${props => props.width}%;
  display: flex;
  flex-direction: ${props => props.direction};
  margin-right: 1em;
  :last-child {
    margin-right: 0;
  }
`;

export const LogoSoup = styled.img`
  width: 35%;
  margin-bottom: 6em;
`;

export const LoginChoiceLogo = styled.img`
  fill: ${colors.vodaRed};
  margin-bottom: 1em;
  align-self: center;
  width: 2.2em;
`;

LoginInputGroup.propTypes = {
  direction: PropTypes.string,
  width: PropTypes.string
};
