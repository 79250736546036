import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import routes from "./routes";
import { setShowError, setHideError } from "../../store/error/actions";

const DevelopmentHelperStyled = styled.ul`
  position: absolute;
  left: 0;
  top: 0;
`;

export const DevelopmentHelper = connect(
  null,
  { showError: setShowError, hideError: setHideError }
)(({ showError, hideError }) => (
  <DevelopmentHelperStyled>
    {routes.map((route, i) => (
      <li key={i}>
        <Link to={route.path}>{route.path}</Link>
      </li>
    ))}
    <li
      onClick={e => {
        e.preventDefault();
        showError();
      }}
    >
      <button>Set error state</button>
    </li>
    <li
      onClick={e => {
        e.preventDefault();
        hideError();
      }}
    >
      <button>Remove errors</button>
    </li>
  </DevelopmentHelperStyled>
));
