import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { setShowError, setHideError } from "../../store/error/actions";
import { Header } from "../Shared";

const SEND_FORGOT_PASSWORD_EMAIL = gql`
  mutation sendForgotPasswordEmail($resource: String!) {
    sendForgotPasswordEmail(resource: $resource)
  }
`;

function MutationComponent({ showError, hideError }) {
  return (
    <Mutation
      mutation={SEND_FORGOT_PASSWORD_EMAIL}
      onCompleted={() => {
        hideError();
      }}
      onError={() => {
        showError();
      }}
    >
      {(sendForgotPasswordEmail, { data, loading }) => {
        if (data) {
          return <Header header="Hlekkur hefur verið sendur á netfangið." />;
        }
        return (
          <ForgotPasswordForm
            mutation={sendForgotPasswordEmail}
            loading={loading}
          />
        );
      }}
    </Mutation>
  );
}

const ForgotPassword = withRouter(
  connect(
    null,
    { showError: setShowError, hideError: setHideError }
  )(MutationComponent)
);

export default ForgotPassword;

MutationComponent.propTypes = {
  showError: PropTypes.func.isRequired,
  hideError: PropTypes.func.isRequired
};
