import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  InputGroup,
  Input,
  InputLabel,
  Button,
  ButtonGroup,
  Spinner
} from "../Shared";
import {
  setPhoneNumber,
  setSmsVerificationClaim
} from "../../store/userInfo/actions";
import { getPhoneNumber } from "../../store/userInfo/selectors";

function handleError(error, inputError) {
  if (error) {
    return "Óvænt villa kom upp vinsamlegast reyndu aftur.";
  }
  if (inputError) {
    return "Athugaðu inslátt símanúmers";
  }
  return "Veldu símanúmer";
}

function UserInfoPhoneNumberComponent({
  setNumber,
  mutation,
  phoneNumber,
  error,
  loading,
  setVerificationClaim
}) {
  const [inputError, setInputError] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);

  useEffect(() => {
    if (error && !inputError) {
      setButtonActive(true);
      setInputError(true);
    }
  });

  function handleMutation(e) {
    e.preventDefault();
    setButtonActive(false);
    mutation({ variables: { phoneNumber } })
      .then(({ data }) => {
        setVerificationClaim(data.sendVerifyPhoneNumberSms.claim);
      })
      .catch(() => {
        setButtonActive(true);
      });
  }

  return (
    <>
      <InputLabel inputError={inputError}>
        {handleError(error, inputError)}
      </InputLabel>
      <InputGroup direction="row" width="100" marginBottom="2" marginRight="0">
        <Input
          autoFocus
          inputError={inputError}
          width="50"
          type="tel"
          placeholder="Símanúmer"
          marginRight="1"
          minLength="7"
          maxLength="7"
          onChange={e => {
            e.preventDefault();
            setNumber(e.target.value);
            setButtonActive(e.target.value.length === 7);
            setInputError(inputError && e.target.value.length < 7);
          }}
          onBlur={e => {
            e.preventDefault();
            setInputError(e.target.value.length < 7);
          }}
        />
        <ButtonGroup width="50">
          <Button
            marginTop="0"
            marginRight="0"
            width="100"
            active={buttonActive}
            disabled={!buttonActive}
            onClick={e => handleMutation(e)}
          >
            {loading ? <Spinner button /> : "Senda SMS"}
          </Button>
        </ButtonGroup>
      </InputGroup>
    </>
  );
}

function mapStateToProps(state) {
  return {
    phoneNumber: getPhoneNumber(state)
  };
}

const UserInfoPhoneNumberForm = connect(
  mapStateToProps,
  {
    setNumber: setPhoneNumber,
    setVerificationClaim: setSmsVerificationClaim
  }
)(UserInfoPhoneNumberComponent);

export default UserInfoPhoneNumberForm;

UserInfoPhoneNumberComponent.propTypes = {
  setNumber: PropTypes.func.isRequired,
  mutation: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  error: PropTypes.object, // eslint-disable-line
  loading: PropTypes.bool.isRequired,
  setVerificationClaim: PropTypes.func.isRequired
};
