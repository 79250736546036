import React, { Fragment } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import EidForm from "./EidForm";
import EIDControlCode from "./EIDControlCode";
import GetUser from "../GetUser/GetUser";

import { setHideError } from "../../store/error/actions";

const LOGIN_EID_STREAM = gql`
  mutation loginUserUsingEIDStream($phoneNumber: String!) {
    loginUserUsingEIDStream(phoneNumber: $phoneNumber) {
      claim
    }
  }
`;

const LOGIN_EID = gql`
  mutation loginUserUsingEID($phoneNumber: String!) {
    loginUserUsingEID(phoneNumber: $phoneNumber) {
      claim
      controlCode
    }
  }
`;

function MutationComponent({ hideError, location }) {
  return (
    <Mutation
      mutation={LOGIN_EID}
      onCompleted={() => {
        hideError();
      }}
    >
      {(loginEid, { data, loading }) => {
        return<Fragment>
          <EidForm mutation={loginEid} data={data} loading={loading} />
          {data && <EIDControlCode
            controlCode={data.loginUserUsingEID.controlCode}
            claim={data.loginUserUsingEID.claim}
          >
            <GetUser
              claim={data.loginUserUsingEID.claim}
              path={location.pathname}
            />
          </EIDControlCode>}
        </Fragment>;
      }}
    </Mutation>
  );
}

const Eid = withRouter(
  connect(
    null,
    { hideError: setHideError }
  )(MutationComponent)
);

export default Eid;

MutationComponent.propTypes = {
  hideError: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired //eslint-disable-line
};
