import UserInfo from "../UserInfo/UserInfo";
import ValidateUserEmail from "../Validate/ValidateUserEmail";
import ValidateBankEdoc from "../Validate/ValidateBankEdoc";
import Continue from "../Continue/Continue";
import ChooseAccount from "../ChooseAccount/ChooseAccount";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import ResetPassword from "../ResetPassword/ResetPassword";
import RegistrationConfirmUserInfo from "../Registration/ConfirmUserInfo/RegistrationConfirmUserInfo";
import UserLoggedIn from "../UserLoggedIn/UserLoggedIn";
import Eid from "../Eid/Eid";
import UserPass from "../UserPass/UserPass";
import Registration from "../Registration/Registration";

const routes = [
  { path: "/eid", component: Eid },
  { path: "/userpass", component: UserPass },
  {
    path: "/login",
    component: Continue,
    props: { exact: true }
  },
  {
    path: "/registration",
    component: Registration
  },
  {
    path: "/validation/email/:transaction",
    component: ValidateUserEmail
  },
  {
    path: "/validation/bank/:transaction",
    component: ValidateBankEdoc
  },
  {
    path: "/userinfo",
    component: UserInfo,
    private: true,
    states: ["EXPIRED_PASSWORD", "NEEDS_USER_INFO"]
  },
  {
    path: "/redirect",
    component: UserLoggedIn,
    private: true,
    states: ["ACTIVE", "CREATED_EMAIL_UNVERIFIED"]
  },
  {
    path: "/chooseaccount",
    component: ChooseAccount,
    private: true,
    states: ["ACTIVE", "CREATED_EMAIL_UNVERIFIED"]
  },
  { path: "/forgotpassword", component: ForgotPassword },
  { path: "/resetpassword", component: ResetPassword },
  {
    path: "/registration/step4",
    private: true,
    states: ["REGISTRATION_NEEDS_USER_INFO"],
    component: RegistrationConfirmUserInfo
  }
];

export default routes;
