import {
  SET_EMAIL,
  SET_PHONE_NUMBER,
  SET_PASSWORD,
  TOGGLE_TERMS_ACCEPTED,
  SET_SMS_CODE,
  SET_SMS_VERIFICATION_CLAIM,
  SET_PHONE_VERIFIED,
  SET_NEEDS_PHONE_VERIFICATION,
  SET_SHOW_MESSAGE
} from "./actions";

const initialStateUserInfo = {
  email: "",
  phoneNumber: "",
  password: "",
  termsAccepted: false,
  smsCode: "",
  smsVerificationClaim: "",
  phoneVerified: false,
  needsPhoneVerification: true,
  showMessage: true
};

export default function userInfo(state = initialStateUserInfo, action) {
  switch (action.type) {
    case SET_EMAIL:
      return {
        ...state,
        email: action.email
      };
    case SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.phoneNumber
      };
    case SET_PASSWORD:
      return {
        ...state,
        password: action.password
      };
    case TOGGLE_TERMS_ACCEPTED:
      return {
        ...state,
        termsAccepted: !state.termsAccepted
      };
    case SET_SMS_CODE:
      return {
        ...state,
        smsCode: action.code
      };
    case SET_SMS_VERIFICATION_CLAIM:
      return {
        ...state,
        smsVerificationClaim: action.claim
      };
    case SET_PHONE_VERIFIED:
      return {
        ...state,
        phoneVerified: action.verified
      };
    case SET_NEEDS_PHONE_VERIFICATION:
      return {
        ...state,
        needsPhoneVerification: action.needsVerification
      };
    case SET_SHOW_MESSAGE:
      return {
        ...state,
        showMessage: action.showMessage
      };
    default:
      return state;
  }
}
