import React, { useEffect } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import routes from "../Shared/routes";
import { PrivateRoute } from "../Shared/PrivateRoute";
import { stateEqualityCheck } from "../../utility/stateEqualityCheck";
import { setLoginType } from "../../store/login/actions";

/**
 * Check and validate that a user is in a current state and then redirect or return a route
 * according to the state the user is in currently.
 * @param {*} The current user state as fetched from backend
 * @param {*} The route user is currently on
 */
function UserStateFetched({ state, company, currentRoute, loginType }) {
  useEffect(() => {
    const type = company ? "COMPANY_INDIVIDUAL" : "INDIVIDUAL";
    loginType(type);
  }, [company]);
  let routeTo = <Redirect to="/" />;
  if (currentRoute === "/") {
    return <Redirect to="/eid" />;
  }
  routes.some(route => {
    const privateStateEqual =
      route.private && stateEqualityCheck(state, route.states);
    const privateStateRouteEqual =
      privateStateEqual && route.path === currentRoute;
    if (privateStateRouteEqual) {
      routeTo = <PrivateRoute path={route.path} component={route.component} />;
      return true;
    }
    if (privateStateEqual) {
      routeTo = <Redirect to={route.path} />;
    }
  });
  return routeTo;
}

export default withRouter(
  connect(
    null,
    { loginType: setLoginType }
  )(UserStateFetched)
);
