import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { connect } from "react-redux";
import { hasToken } from "../../utility";
import { Spinner } from "../Shared";
import { setHideError, setShowError } from "../../store/error/actions";

const ENVIRONMENT = gql`
  query environment($id: String!) {
    environment(id: $id) {
      key
      value
    }
  }
`;

function Divert(props) {
  const { aid, claim, redirect, company, uri } = props;
  const uriParsed = uri.replace(/\/+$/, "");
  if (aid === "misi" && company) {
    return <Redirect to="/chooseaccount" />;
  }
  if (claim && uri && hasToken()) {
    window.sessionStorage.clear();
    if (redirect) {
      window.location.assign(
        `${uriParsed}/${claim}?redirectUri=${encodeURIComponent(redirect)}`
      );
    } else {
      window.location.assign(`${uriParsed}/${claim}`);
    }
  } else {
    window.sessionStorage.clear();
    return <Redirect to="/" />;
  }
  return <Spinner />;
}

function Continue({
  aid,
  uri,
  claim,
  redirect,
  company,
  showError,
  hideError
}) {
  if (!uri) {
    return (
      <Query
        query={ENVIRONMENT}
        variables={{ id: "misiWeb" }}
        onCompleted={() => hideError()}
        onError={() => showError()}
      >
        {({ data, loading }) => {
          if (loading) {
            return <Spinner />;
          }
          if (data) {
            uri = data.environment.find(obj => obj.key === "continueUri").value;
            return (
              <Divert
                aid={aid}
                claim={claim}
                redirect={redirect}
                company={company}
                uri={uri}
              />
            );
          }
        }}
      </Query>
    );
  }
  return (
    <Divert
      aid={aid}
      claim={claim}
      redirect={redirect}
      company={company}
      uri={uri}
    />
  );
}

function UserLoggedIn({ company, showError, hideError }) {
  const aid = window.sessionStorage.getItem("aid");
  const claim = window.sessionStorage.getItem("c");
  const uri = window.sessionStorage.getItem("continueUri");
  const redirectUri = window.sessionStorage.getItem("redirectUri");

  return (
    <Continue
      aid={aid}
      claim={claim}
      company={company}
      uri={uri}
      redirect={redirectUri}
      showError={showError}
      hideError={hideError}
    />
  );
}

export default withRouter(
  connect(
    null,
    { showError: setShowError, hideError: setHideError }
  )(UserLoggedIn)
);
