export const SHOW_ERROR = "SHOW_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";

export const setShowError = message => ({
  type: SHOW_ERROR,
  message,
  showError: true
});

export const setHideError = () => ({
  type: HIDE_ERROR,
  message: "",
  showError: false
});
