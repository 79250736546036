import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import linkIcon from "../../img/icon-link.svg";
import {
  Button,
  Input,
  Spinner,
  Form,
  InputGroup,
  Header,
  InputLabel
} from "../Shared";
import { EidHelpLink, EidHelpLinkContainer, EidLinkIcon } from "./styles";
import { removeToken } from "../../utility";
import { setShowError } from "../../store/error/actions";

function EidForm({ mutation, loading, showError }) {
  removeToken();
  const [buttonActive, setButtonActive] = useState(false);
  const [inputActive, setInputActive] = useState(true);
  const [inputError, setInputError] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  function handleChange(e) {
    e.preventDefault();
    setButtonActive(e.target.value.length === 7);
    setInputError(false);
    setPhoneNumber(e.target.value);
  }

  function handleInvalidInput(e) {
    e.preventDefault();
    setInputErrorMessage("Vinsamlegast sláðu inn gilt símanúmer");
    setInputError(true);
  }

  function handleError(error) {
    error.graphQLErrors.forEach(({ extensions }) => {
      const { code } = extensions;
      switch (code) {
        case "CANCELLED":
          setInputErrorMessage("Notandi hætti við.");
          setInputError(true);
          break;
        case "DEADLINE_EXCEEDED":
          setInputErrorMessage("Auðkenning tók of langan tíma, reyndu aftur.");
          setInputError(true);
          break;
        case "NOT_FOUND":
          setInputErrorMessage("Notandi er ekki með virk rafræn skilríki.");
          setInputError(true);
          break;
        default:
          setInputErrorMessage("Óvænt villa, vinsamlegast reyndu aftur.");
          setInputError(true);
      }
    });
  }

  function submitLogin(e) {
    e.preventDefault();
    if (!buttonActive) {
      return;
    }
    setInputActive(false);
    setButtonActive(false);
    mutation({ variables: { phoneNumber } }).catch(error => {
      handleError(error);
      setInputActive(true);
      setButtonActive(true);
    });
  }

  return (
    <Form onSubmit={e => submitLogin(e)}>
      <EidHelpLinkContainer>
        <EidHelpLink
          href="https://www.audkenni.is/"
          target="_blank"
          rel="noreferrer"
          tabIndex="-1"
        >
          Rafræn skilríki? <EidLinkIcon src={linkIcon} />
        </EidHelpLink>
      </EidHelpLinkContainer>
      <Header
        header="Sláðu inn farsímanúmer með rafrænum skilríkjum."
        description="Ef þú ert með rafræn skilríki er nóg fyrir þig að skrá þig hér.​"
      />
      <InputLabel inputError={inputError}>
        {inputError ? inputErrorMessage : "Símanúmer"}
      </InputLabel>
      <InputGroup direction="row" width="100">
        <Input
          disabled={!inputActive}
          marginRight="1.3"
          type="tel"
          pattern="\d{3}?[\s-]\d{4}|\d{7}"
          minLength="7"
          maxLength="7"
          required
          placeholder="Farsímanúmer"
          onChange={e => handleChange(e)}
          width="60"
          inputError={inputError}
          onInvalid={e => handleInvalidInput(e)}
          autoFocus
        />
        <Button active={buttonActive} type="submit">
          {loading ? <Spinner button /> : "Auðkenna"}
        </Button>
      </InputGroup>
    </Form>
  );
}

export default connect(
  null,
  { showError: setShowError }
)(EidForm);

EidForm.propTypes = {
  mutation: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
