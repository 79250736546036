export const SET_VERIFIED_CLAIM = "SET_VERIFIED_CLAIM";
export const SET_SMS_REGISTRATION_CLAIM = "SET_SMS_REGISTRATION_CLAIM";
export const SET_SMS_VERIFIED_CLAIM = "SET_SMS_VERIFIED_CLAIM";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";

export const setVerifiedClaim = claim => ({
  type: SET_VERIFIED_CLAIM,
  claim
});

export const setSmsRegistrationClaim = claim => ({
  type: SET_SMS_REGISTRATION_CLAIM,
  claim
});

export const setSmsVerifiedClaim = claim => ({
  type: SET_SMS_VERIFIED_CLAIM,
  claim
});

export const setCurrentStep = step => ({
  type: SET_CURRENT_STEP,
  step
});
