import React from "react";
import gql from "graphql-tag";
import queryString from "query-string";
import { Mutation } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import ResetPasswordForm from "./ResetPasswordForm";
import { setShowError, setHideError } from "../../store/error/actions";

const RESET_USER_PASSWORD = gql`
  mutation resetUserPassword($claim: String!, $password: String!) {
    resetUserPassword(claim: $claim, password: $password)
  }
`;

function MutationComponent({ showError, hideError, location }) {
  // get claim from query string
  const queryStringParam = location.search;
  const parsedQueryStringParam = queryString.parse(queryStringParam);
  const { claim } = parsedQueryStringParam;
  return (
    <Mutation
      mutation={RESET_USER_PASSWORD}
      onCompleted={() => {
        hideError();
      }}
      onError={() => {
        showError();
      }}
    >
      {(resetUserPassword, { data, loading }) => {
        if (data) {
          return <div>Lykilorðinu hefur verið breytt.</div>;
        }

        return (
          <ResetPasswordForm
            mutation={resetUserPassword}
            loading={loading}
            claim={claim}
          />
        );
      }}
    </Mutation>
  );
}

const ResetPassword = connect(
  null,
  { showError: setShowError, hideError: setHideError }
)(withRouter(MutationComponent));

export default ResetPassword;

MutationComponent.propTypes = {
  showError: PropTypes.func.isRequired,
  hideError: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired //eslint-disable-line
};
