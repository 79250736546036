import styled from "styled-components";
import { Button } from "../Shared";

export const ChooseAccountButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  width: 100%;
  color: black;
  font-size: 2.5em;
  padding: 1.4em;
  margin-bottom: 1.35em;
  :last-child {
    margin-bottom: 0;
  }
`;

export const ChooseAccountButtonArrow = styled.img`
  width: 1.4em;
`;
