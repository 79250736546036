import React from "react";
import styled from "styled-components";
import colors from "./colors";

export const Spinner = props => (
  <StyledSpinner viewBox="0 0 50 50" button={!!props.button}>
    <StyledCircle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="3"
    />
  </StyledSpinner>
);

const StyledCircle = styled.circle`
  stroke: ${colors.vodaRed};
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
`;

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  padding: 0;
  margin: 0 0 0 0;
  width: ${props => (props.button ? "0.9em" : "1.8em")};
  height: ${props => (props.button ? "0.9em" : "1.8em")};

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
