import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  InputGroup,
  Input,
  InputLabel,
  Button,
  ButtonGroup,
  Spinner
} from "../Shared";
import { setSmsCode, setPhoneVerified } from "../../store/userInfo/actions";
import {
  getSmsCode,
  getSmsVerificationClaim
} from "../../store/userInfo/selectors";

function UserInfoSmsFormComponent({
  setCode,
  mutation,
  smsCode,
  claim,
  setVerified,
  loading
}) {
  const [inputError, setInputError] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);

  function handleMutation(e) {
    e.preventDefault();
    setButtonActive(false);
    mutation({ variables: { smsCode, claim } })
      .then(() => {
        setVerified(true);
      })
      .catch(() => {
        setInputError(true);
        setButtonActive(true);
      });
  }
  return (
    <>
      <InputLabel inputError={inputError}>
        {inputError
          ? "Athugaðu innslátt á kóða"
          : "SMS kóði sendur á símanúmer"}
      </InputLabel>
      <InputGroup direction="row" width="100" marginBottom="2" marginRight="0">
        <Input
          autoFocus
          inputError={inputError}
          width="50"
          placeholder="SMS kóði"
          marginRight="1"
          minLength="4"
          maxLength="4"
          onChange={e => {
            setCode(e.target.value);
            setButtonActive(e.target.value.length === 4);
            setInputError(inputError && e.target.value.length < 4);
          }}
          onBlur={e => setInputError(e.target.value.length < 4)}
        />
        <ButtonGroup width="50">
          <Button
            marginTop="0"
            marginRight="0"
            width="100"
            active={buttonActive}
            disabled={!buttonActive}
            onClick={e => handleMutation(e)}
          >
            {loading ? <Spinner button /> : "Staðfesta kóða"}
          </Button>
        </ButtonGroup>
      </InputGroup>
    </>
  );
}

function mapStateToProps(state) {
  return {
    smsCode: getSmsCode(state),
    claim: getSmsVerificationClaim(state)
  };
}

const UserInfoSmsForm = connect(
  mapStateToProps,
  { setCode: setSmsCode, setVerified: setPhoneVerified }
)(UserInfoSmsFormComponent);

export default UserInfoSmsForm;

UserInfoSmsFormComponent.propTypes = {
  setCode: PropTypes.func.isRequired,
  mutation: PropTypes.func.isRequired,
  smsCode: PropTypes.string.isRequired,
  claim: PropTypes.string.isRequired,
  error: PropTypes.object, // eslint-disable-line
  setVerified: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
