import styled from "styled-components";

export const StyledUserInfoForm = styled.form`
  width: 100%;
`;

export const TermsLink = styled.a`
  margin-top: 2em;
  text-decoration: none;
  border-bottom: 1px solid black;
  color: black !important;
  font-size: 1em;
  &:hover {
    cursor: pointer;
  }
`;

export const TermsContainer = styled.div`
  font-size: 1em;
  margin-right: 1em;
`;
