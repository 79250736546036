import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { withRouter } from "react-router-dom";
import RegistrationSsnForm from "./RegistrationSsnForm";
import EDocSentConfirmation from "./EDocSentConfirmation";

const EDOC = gql`
  mutation sendRegistrationEDocument($username: String!) {
    sendRegistrationEDocument(username: $username)
  }
`;

function MutationComponent() {
  return (
    <Mutation mutation={EDOC}>
      {(sendRegistrationEDocument, { data, loading, error }) => {
        if (data) {
          return <EDocSentConfirmation />;
        }
        return (
          <RegistrationSsnForm
            mutation={sendRegistrationEDocument}
            loading={loading}
            data={data}
            error={error}
          />
        );
      }}
    </Mutation>
  );
}

const RegistrationSsn = withRouter(MutationComponent);

export default RegistrationSsn;
