import React, { useEffect } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import UserInfoForm from "./UserInfoForm";
import { setShowError, setHideError } from "../../store/error/actions";
import { setNeedsPhoneVerification } from "../../store/userInfo/actions";

const NEW_USER_INFO = gql`
  mutation setNewUserInfo(
    $email: String!
    $phoneNumber: String!
    $masterPassword: String!
    $termsAccepted: Boolean!
  ) {
    setNewUserInfo(
      email: $email
      phoneNumber: $phoneNumber
      masterPassword: $masterPassword
      termsAccepted: $termsAccepted
    ) {
      name
      state
      company
    }
  }
`;

function UserInfo({
  showError,
  hideError,
  history,
  skipVerification,
  setNeedsVerification
}) {
  useEffect(() => {
    setNeedsVerification(!skipVerification);
  }, [skipVerification]);
  return (
    <Mutation
      mutation={NEW_USER_INFO}
      onCompleted={({ setNewUserInfo }) => {
        hideError();
        const { company, state } = setNewUserInfo;
        window.sessionStorage.setItem("state", state);
        if (company) {
          history.push("/chooseaccount");
        } else {
          history.push("/redirect");
        }
      }}
      onError={() => showError()}
    >
      {(setNewUserInfo, { loading }) => (
        <UserInfoForm mutation={setNewUserInfo} loading={loading} />
      )}
    </Mutation>
  );
}

export default withRouter(
  connect(
    null,
    {
      showError: setShowError,
      hideError: setHideError,
      setNeedsVerification: setNeedsPhoneVerification
    }
  )(UserInfo)
);

UserInfo.propTypes = {
  showError: PropTypes.func.isRequired,
  hideError: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired, // eslint-disable-line
  skipVerification: PropTypes.bool,
  setNeedsVerification: PropTypes.func.isRequired
};

UserInfo.defaultProps = {
  skipVerification: false
};
