import React, { useEffect } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Spinner } from "../Shared";
import {
  setVerifiedClaim,
  setCurrentStep
} from "../../store/registration/actions";
import { setShowError } from "../../store/error/actions";

const VERIFY_REGISTRATION_BANK_CALLBACK = gql`
  mutation verifyRegistrationEDocumentCallback($claim: String!) {
    verifyRegistrationEDocumentCallback(claim: $claim) {
      claim
    }
  }
`;

function DoVerification({ mutation }) {
  useEffect(() => {
    mutation();
  });

  return <Spinner />;
}

function handleError(error, showError, currentStep, history) {
  if (error) {
    error.graphQLErrors.forEach(({ extensions }) => {
      switch (extensions.code) {
        case "NOT_FOUND":
          showError("Hlekkur útrunninn, vinsamlegast reyndu aftur");
          currentStep(1);
          history.push("/registration");
          break;
        default:
          showError("Óvænt villa kom upp, vinsamlegast reyndu aftur");
          currentStep(1);
          history.push("/");
          break;
      }
    });
  }
}

function ValidateBankEdocMutation({
  match,
  setVerifiedClaim,
  history,
  currentStep,
  showError
}) {
  return (
    <Mutation
      mutation={VERIFY_REGISTRATION_BANK_CALLBACK}
      variables={{ claim: match.params.transaction }}
      onCompleted={({ verifyRegistrationEDocumentCallback: result }) => {
        currentStep(2);
        setVerifiedClaim(result.claim);
      }}
      onError={error => handleError(error, showError, currentStep, history)}
    >
      {(mutation, { data, loading }) => {
        if (loading) {
          return <Spinner />;
        }
        if (data) {
          return <Redirect to="/registration" />;
        }
        return <DoVerification mutation={mutation} />;
      }}
    </Mutation>
  );
}

const ValidateBankEdoc = connect(
  null,
  { setVerifiedClaim, currentStep: setCurrentStep, showError: setShowError }
)(ValidateBankEdocMutation);

export default ValidateBankEdoc;
