import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "../Shared/colors";

const StepsNavigationBar = styled.ul`
  width: 100%;
  height: 25%;
  padding: 0;
  margin: 0;
`;

const StepsNavigationBarItem = styled.li`
  display: inline-block;
  width: 25%;
  border: solid 1px ${colors.lightGrey};
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: 1.5em;
  background-color: ${props => (props.lightGrey ? colors.lightGrey : "")};
`;

const Step = styled.div`
  width: 100%;
  font-size: 1.3em;
  font-weight: ${props => (props.selected ? "bold" : null)};
  text-align: right;
  color: ${props => (props.selected ? colors.vodaRed : colors.darkGrey)};
`;

const ListItemText = styled.p`
  margin: 0;
  font-size: 1.3em;
  font-weight: ${props => (props.selected ? "bold" : null)};
  color: ${props => (props.selected ? null : colors.darkGrey)};
  text-align: left;
  margin-left: 1.3em;
  width: 100%;
`;

export default function StepsNavigation({ step }) {
  return (
    <StepsNavigationBar>
      <StepsNavigationBarItem lightGrey={step > 1}>
        <Step selected={step === 1}>1</Step>
        <ListItemText selected={step === 1}>Kennitala</ListItemText>
      </StepsNavigationBarItem>
      <StepsNavigationBarItem lightGrey={step > 2}>
        <Step selected={step === 2}>2</Step>
        <ListItemText selected={step === 2}>Farsími</ListItemText>
      </StepsNavigationBarItem>
      <StepsNavigationBarItem lightGrey={step > 3}>
        <Step selected={step === 3}>3</Step>
        <ListItemText selected={step === 3}>SMS-kóði</ListItemText>
      </StepsNavigationBarItem>
      <StepsNavigationBarItem>
        <Step selected={step === 4}>4</Step>
        <ListItemText selected={step === 4}>Notandi</ListItemText>
      </StepsNavigationBarItem>
    </StepsNavigationBar>
  );
}

StepsNavigation.propTypes = {
  step: PropTypes.number.isRequired
};
