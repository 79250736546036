import styled from "styled-components";

export const ErrorBox = styled.div`
  width: 100%;
  font-size: 1.2em;
  font-weight: bold;
  padding: 1em;
  background: rgba(230, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorText = styled.p`
  color: white;
  margin: 0 0 0 1em;
`;

export const ErrorLogo = styled.img`
  width: 1.65em;
`;
