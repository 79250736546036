import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import SmsMutation from "./SmsMutation";
import UserPassForm from "./UserPassForm";
import { setShowError, setHideError } from "../../store/error/actions";

const LOGIN_USING_PASSWORD = gql`
  mutation loginUsingPassword($username: String!, $password: String!) {
    loginUsingPassword(username: $username, password: $password) {
      claim
      maskedPhoneNumber
    }
  }
`;

function MutationComponent({ hideError, location }) {
  return (
    <Mutation
      mutation={LOGIN_USING_PASSWORD}
      onCompleted={() => {
        hideError();
      }}
    >
      {(loginUsingPassword, { data, loading }) => {
        if (data) {
          return <SmsMutation location={location} data={data} />;
        }

        return (
          <UserPassForm
            mutation={loginUsingPassword}
            data={data}
            loading={loading}
          />
        );
      }}
    </Mutation>
  );
}

const UserPass = withRouter(
  connect(
    null,
    { showError: setShowError, hideError: setHideError }
  )(MutationComponent)
);

export default UserPass;

MutationComponent.propTypes = {
  showError: PropTypes.func.isRequired,
  hideError: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired // eslint-disable-line
};
