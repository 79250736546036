import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Input,
  Button,
  Spinner,
  Form,
  InputGroup,
  Header,
  InputLabel
} from "../../Shared";
import { getSmsRegistrationClaim } from "../../../store/registration/selectors";

function FormComponent({ mutation, loading, claim }) {
  const [smsCode, setCode] = useState("");
  const [buttonActive, setButtonActive] = useState(false);
  const [inputError, setInputError] = useState(false);
  const inputErrorMessage =
    "Vinsamlegast sláðu inn gildan fjögurra tölustafa kóða";

  const handleSubmit = e => {
    e.preventDefault();
    setButtonActive(false);
    mutation({ variables: { smsCode, claim } }).catch(() => {
      setButtonActive(true);
    });
  };

  const handleInvalidInput = e => {
    e.preventDefault();
    setInputError(true);
  };

  const handleChange = e => {
    e.preventDefault();
    e.target.value.length === 4
      ? setButtonActive(true)
      : setButtonActive(false);

    setCode(e.target.value);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Header
        header="Staðfesta SMS kóða"
        description="Vinsamlega staðfestu SMS kóða sem barst í farsímann þinn"
      />
      <InputLabel inputError={inputError}>
        {inputError ? inputErrorMessage : "Sláðu inn SMS kóðann"}
      </InputLabel>
      <InputGroup direction="row" width="100">
        <Input
          onChange={handleChange}
          onInvalid={handleInvalidInput}
          width="40"
          type="text"
          minLength="4"
          maxLength="4"
          placeholder="Kóði"
          autoFocus
        />
        <Button
          active={buttonActive}
          type="submit"
          width="50"
          height="40"
          margin="auto"
        >
          {loading ? <Spinner button /> : "Staðfesta"}
        </Button>
      </InputGroup>
    </Form>
  );
}

const RegistrationSmsForm = connect(state => ({
  claim: getSmsRegistrationClaim(state)
}))(FormComponent);

export default RegistrationSmsForm;

FormComponent.propTypes = {
  mutation: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  claim: PropTypes.string.isRequired
};
