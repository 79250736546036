import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  InputGroup,
  Input,
  InputLabel,
  InputPasswordContainer,
  InputHideShowPassIcon
} from "../Shared";
import { setPassword } from "../../store/userInfo/actions";
import showPass from "../../img/show_password.png";
import hidePass from "../../img/hide_password.png";

function UserInfoPasswordComponent({ setSelectedPassword }) {
  const [inputError, setInputError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <InputLabel inputError={inputError}>
        {inputError
          ? "Lykilorð þarf að vera minnst 8 stafir."
          : "Veldu nýtt lykilorð"}
      </InputLabel>
      <InputGroup direction="row" width="100" marginRight="0">
        <Input
          width="100"
          minLength="5"
          name="password"
          type={showPassword ? "text" : "password"}
          onChange={e => {
            setSelectedPassword(e.target.value);
            setInputError(inputError && e.target.value.length < 8);
          }}
          placeholder="Nýtt lykilorð"
          inputError={inputError}
          onBlur={e => setInputError(e.target.value.length < 8)}
        />
        <InputPasswordContainer>
          <InputHideShowPassIcon
            src={showPassword ? hidePass : showPass}
            onClick={() => setShowPassword(!showPassword)}
          />
        </InputPasswordContainer>
      </InputGroup>
    </>
  );
}

const UserInfoPassword = connect(
  null,
  { setSelectedPassword: setPassword }
)(UserInfoPasswordComponent);

export default UserInfoPassword;

UserInfoPasswordComponent.propTypes = {
  setSelectedPassword: PropTypes.func.isRequired
};
