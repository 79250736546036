import React, { useEffect } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { withRouter } from "react-router-dom";
import { Spinner, Header, Form } from "../Shared";

const VALIDATE_USER_EMAIL = gql`
  mutation validateUserEmail($token: String!) {
    validateUserEmail(token: $token)
  }
`;

function MutationComponent({ match }) {
  return (
    <Mutation
      mutation={VALIDATE_USER_EMAIL}
      variables={{ token: match.params.transaction }}
    >
      {(mutation, { data, loading, error }) => {
        if (data) {
          return (
            <Form>
              <Header
                header="Netfang hefur verið staðfest"
                description="Þér er óhætt að loka þessum glugga"
              />
            </Form>
          );
        }
        if (loading) {
          return <Spinner />;
        }
        if (error) {
          return "Ekki tókst að staðfesta netfang reyndu aftur.";
        }
        return <DoValidation mutation={mutation} />;
      }}
    </Mutation>
  );
}

function DoValidation({ mutation }) {
  useEffect(() => {
    mutation();
  });
  return "";
}

const ValidateUserEmail = withRouter(MutationComponent);

export default ValidateUserEmail;
