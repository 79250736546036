import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: ${props => (props.padding ? props.padding : "3em")};
  width: 100%;
`;

export const FormDescription = styled.p`
  order: 0;
  flex-basis: 100%;
  font-size: 1.3em;
  margin-top: 1em;
  margin-bottom: 2.5em;
`;
