import React from "react";
import { Route, Redirect } from "react-router-dom";
import { hasToken } from "../../utility";

export function PrivateRoute({ component: ComponentToRoute, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        hasToken() ? (
          <ComponentToRoute {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}
