import styled from "styled-components";

export const EidHelpLinkContainer = styled.div`
  width: 100%;
  text-align: center;
`;

export const EidHelpLink = styled.a`
  float: right;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 1px solid black;
  font-family: "Vodafone", Helvetica, Arial, sans-serif;
  color: black !important;
  font-size: 1em;
  &:hover {
    cursor: pointer;
  }
`;

export const EidLinkIcon = styled.img`
  width: 1em;
`;
