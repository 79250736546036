import "cross-fetch/polyfill";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { hasToken } from "./utility";

const httpLink = createHttpLink({
  uri: "/innskraning/graphql"
});

const authLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem("id_token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          switch (extensions.code) {
            case "UNAUTHENTICATED":
              hasToken();
              break;
            default:
              break;
          }
        });
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    authLink.concat(httpLink)
  ]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network"
    },
    query: {
      fetchPolicy: "cache-and-network"
    },
    mutate: {
      fetchPolicy: "cache-and-network"
    }
  },
  cache: new InMemoryCache()
});

export default client;
