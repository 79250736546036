import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getErrorMessage } from "../../store/error/selectors";
import { ErrorBox, ErrorLogo, ErrorText } from "./styles";
import exclamationIcon from "../../img/icon-warning.svg";

function Error({ message }) {
  return (
    <ErrorBox>
      <ErrorLogo src={exclamationIcon} />
      <ErrorText>{message}</ErrorText>
    </ErrorBox>
  );
}

const ErrorMessage = connect(state => ({
  message: getErrorMessage(state)
}))(Error);

export default ErrorMessage;

Error.propTypes = {
  message: PropTypes.string
};

Error.defaultProps = {
  message: "Óvænt villa koma upp, vinsamlegast reyndu aftur."
};
