import React from "react";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../store/store";
import Login from "./Login/Login";
import client from "../apolloClient";
import GlobalStyle from "./styles";

const App = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <GlobalStyle />
      <BrowserRouter basename="/innskraning">
        <Login />
      </BrowserRouter>
    </Provider>
  </ApolloProvider>
);

export default App;
