import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import UserInfoPhoneNumberForm from "./UserInfoPhoneNumberForm";
import UserInfoSms from "./UserInfoSms";

const SEND_VERIFY_PHONE_NUMBER_SMS = gql`
  mutation sendVerifyPhoneNumberSms($phoneNumber: String!) {
    sendVerifyPhoneNumberSms(phoneNumber: $phoneNumber) {
      claim
    }
  }
`;

function UserInfoPhoneNumber() {
  return (
    <Mutation mutation={SEND_VERIFY_PHONE_NUMBER_SMS}>
      {(sendVerifyPhoneNumberSms, { loading, data, error }) => {
        if (data) {
          return <UserInfoSms />;
        }
        return (
          <UserInfoPhoneNumberForm
            mutation={sendVerifyPhoneNumberSms}
            loading={loading}
            error={error}
          />
        );
      }}
    </Mutation>
  );
}

export default UserInfoPhoneNumber;
