import { SET_LOGIN_TYPE, SET_USER_STATE } from "./actions";

const loginTypes = {
  INDIVIDUAL: "INDIVIDUAL",
  COMPANY_INDIVIDUAL: "COMPANY_INDIVIDUAL"
};

const intialStateLogin = {
  userState: "",
  loginType: loginTypes.COMPANY_INDIVIDUAL
};

export default function login(state = intialStateLogin, action) {
  switch (action.type) {
    case SET_USER_STATE:
      return {
        ...state,
        userState: action.userState
      };
    case SET_LOGIN_TYPE:
      return {
        ...state,
        loginType: action.loginType
      };
    default:
      return state;
  }
}
