import React from "react";
import PropTypes from "prop-types";
import * as styles from "./styles";
import arrowRight from "../../img/icon-arrow-right.svg";
import { Spinner, Form, Header } from "../Shared";

export default function ChooseAccountForm({ mutation, loading, loginType }) {
  function submitChooseAccount(e, account) {
    if (e) {
      e.preventDefault();
    }
    mutation({ variables: { loginType: account } });
  }

  let account;
  if (loginType === "INDIVIDUAL") {
    account = "INDIVIDUAL";
    submitChooseAccount(null, account);
    return null;
  }
  return (
    <Form
      onSubmit={e => {
        submitChooseAccount(e, account);
      }}
    >
      {loading && <Spinner />}
      <Header header="Þú hefur aðgang að bæði Mínum síðum einstaklinga og fyrirtækja" />
      <styles.ChooseAccountButton
        type="submit"
        onClick={() => {
          account = "INDIVIDUAL";
        }}
      >
        Einstaklingar
        <styles.ChooseAccountButtonArrow src={arrowRight} />
      </styles.ChooseAccountButton>
      <styles.ChooseAccountButton
        type="submit"
        onClick={() => {
          account = "COMPANY";
        }}
      >
        Fyrirtæki
        <styles.ChooseAccountButtonArrow src={arrowRight} />
      </styles.ChooseAccountButton>
    </Form>
  );
}

ChooseAccountForm.propTypes = {
  mutation: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loginType: PropTypes.string.isRequired
};
