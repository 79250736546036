import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Spinner } from "../Shared";
import styled from "styled-components";
import { connect } from "react-redux";
import { setHideError, setShowError } from "../../store/error/actions";

const ControlCodeContainer = styled.div`
  width: 100%;
  margin: 0px;
  padding: 0px;
  padding-bottom: 1em;
  text-align: left;
  display: flex;
  justify-content: space-around;
`;

const ControlCodeText = styled.h5`
  margin-top: 0px;
`;

const TRANSACTION_IN_PROCESS = 'TRANSACTION_IN_PROCESS';

const EID_LOGIN_STATUS = gql`
  query eidLoginStatus($claim: ID!) {
    eidLoginStatus(claim: $claim) {
      status
      statusMessage
    }
  }
`;

function QueryComponent({ claim, controlCode, children, hideError, showError }) {
  window.sessionStorage.setItem("c", claim);
  return (
    <Query
      query={EID_LOGIN_STATUS}
      variables={{claim : claim}}
      pollInterval={2000}
      onError={() => showError()}
      onCompleted={() => hideError()}
    >
      {({ data, loading, error }) => {
        if (error) return <Redirect to="/" />;
        if (!loading && data && data.eidLoginStatus.status !== TRANSACTION_IN_PROCESS) return children;
        return (
        <ControlCodeContainer>
          <ControlCodeText>Öryggiskóðinn þinn er {controlCode}</ControlCodeText>
          <Spinner />
        </ControlCodeContainer>
        );
      }}
    </Query>
  );
}

const EIDControlCode = connect(
  null,
  { showError: setShowError, hideError: setHideError }
)(QueryComponent);

export default EIDControlCode;

QueryComponent.propTypes = {
  showError: PropTypes.func.isRequired,
  hideError: PropTypes.func.isRequired,
  claim: PropTypes.string.isRequired,
  controlCode: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
