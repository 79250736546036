import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Spinner,
  InputLabel,
  Input,
  Form,
  InputGroup,
  Header
} from "../../Shared";
import { getVerifiedClaim } from "../../../store/registration/selectors";

function FormComponent({ mutation, loading, claim }) {
  const [buttonActive, setButtonActive] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const inputErrorMessage = "Vinsamlegast sláðu inn gilt farsímanúmer";

  const handleSubmit = e => {
    e.preventDefault();
    setButtonActive(false);
    mutation({ variables: { phoneNumber, claim } }).catch(() => {
      setInputError(false);
      setButtonActive(true);
    });
  };

  const handleInvalidInput = e => {
    e.preventDefault();
    setInputError(true);
  };

  const handleChange = e => {
    e.preventDefault();
    e.target.value.length === 7
      ? setButtonActive(true)
      : setButtonActive(false);
    setPhoneNumber(e.target.value);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Header
        header="Staðfesta símanúmer"
        description="Fyrir aukið öryggi viljum við biðja þig um að staðfesta símanúmer."
      />
      <InputLabel inputError={inputError}>
        {inputError ? inputErrorMessage : "Sláðu inn farsímanúmer"}
      </InputLabel>
      <InputGroup direction="row" width="100">
        <Input
          autoFocus
          width="50"
          placeholder="Farsímanúmer"
          minLength="7"
          maxLength="7"
          required
          pattern="\d{7}"
          onInvalid={handleInvalidInput}
          onChange={handleChange}
        />
        <Button
          active={buttonActive}
          type="submit"
          height="40"
          margin="auto"
          marginRight="0"
        >
          {loading ? <Spinner button /> : "Fá SMS"}
        </Button>
      </InputGroup>
    </Form>
  );
}

const RegistrationMobileForm = connect(state => ({
  claim: getVerifiedClaim(state)
}))(FormComponent);

export default RegistrationMobileForm;
