import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Spinner } from "../Shared";
import UserStateFetched from "../UserStateFetched/UserStateFetched";
import { setHideError, setShowError } from "../../store/error/actions";

function QueryComponent({ claim, path, hideError, showError }) {
  const GET_USER = gql`
    query user($user_claim: ID!) {
      user(claim: $user_claim) {
        token
        state
        name
        company
        email
      }
    }
  `;

  window.sessionStorage.setItem("c", claim);

  return (
    <Query
      query={GET_USER}
      variables={{ user_claim: claim, cache: +new Date() }}
      onError={() => showError()}
      onCompleted={() => hideError()}
    >
      {({ data, loading, error }) => {
        if (loading) return <Spinner />;
        if (error) return <Redirect to="/" />;
        if (data) {
          window.sessionStorage.setItem("id_token", data.user.token);
          return (
            <UserStateFetched
              state={data.user.state}
              company={data.user.company}
              currentRoute={path}
            />
          );
        }
      }}
    </Query>
  );
}

const GetUser = connect(
  null,
  { showError: setShowError, hideError: setHideError }
)(QueryComponent);

export default GetUser;

QueryComponent.propTypes = {
  showError: PropTypes.func.isRequired,
  hideError: PropTypes.func.isRequired,
  claim: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
};
