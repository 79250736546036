import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "./colors";

export const Input = styled.input`
  transition: all 0.3s ease-in-out;
  font-size: 1.375em;
  font-family: inherit;
  background-color: ${colors.white};
  outline: none;
  border: ${props =>
    props.inputError
      ? `1px ${colors.vodaRed} solid`
      : `1px ${colors.lightGrey} solid`};
  border-radius: 3px;
  box-shadow: none;
  margin: 0 ${props => (props.marginRight ? `${props.marginRight}em` : "auto")}
    0 0;
  padding: 1em 1em;
  width: ${props => props.width}%;
  box-sizing: border-box;
  color: ${props =>
    props.inputError ? `${colors.vodaRed}` : `${colors.darkerGrey}`};

  ::placeholder {
    color: ${props =>
      props.inputError ? `${colors.vodaRed}` : `${colors.darkGrey}`};
  }

  :focus {
    border: ${props =>
      props.inputError
        ? `1px ${colors.vodaRed} solid`
        : `1px ${colors.darkGrey} solid`};
    color: ${props =>
      props.inputError ? `${colors.vodaRed}` : `${colors.darkerGrey}`};
  }

  :hover {
    border: ${props =>
      props.inputError
        ? `1px ${colors.vodaRed} solid`
        : `1px ${colors.darkGrey} solid`};
  }
`;

export const InputGroup = styled.div`
  width: ${props => props.width}%;
  display: flex;
  flex-direction: ${props => props.direction};
  margin-right: ${props => props.marginRight || "1em"};
  margin-bottom: ${props => props.marginBottom || "0"}em;
  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  width: ${props => props.width}%;
  flex-direction: row;
  margin-bottom: 4em;
`;

export const InputPasswordContainer = styled.div`
  width: ${props => props.width}%;
`;

export const InputHideShowPassIcon = styled.img`
  position: absolute;
  margin-top: 1.2em;
  margin-left: -3.5em;
  width: 2em;
  &:hover {
    cursor: pointer;
  }
`;

export const InputLabel = styled.label`
  color: ${props => (props.inputError ? `${colors.vodaRed}` : null)};
  font-weight: bold;
  margin-bottom: 1em;
  display: block;
`;

export const InputText = styled.p`
  order: 0;
  flex-basis: 100%;
  font-size: 1.3em;
  margin-top: 1em;
  margin-bottom: 2.5em;
`;

Input.propTypes = {
  width: PropTypes.string,
  marginRight: PropTypes.string,
  inputError: PropTypes.bool
};

InputLabel.propTypes = {
  inputError: PropTypes.bool
};
