import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import ChooseAccountForm from "./ChooseAccountForm";
import { setShowError, setHideError } from "../../store/error/actions";
import { getLoginType } from "../../store/login/selectors";

const SET_USER_LOGIN_TYPE = gql`
  mutation setUserLoginType($loginType: UserLoginType!) {
    setUserLoginType(loginType: $loginType)
  }
`;

function MutationComponent({ showError, hideError, history, loginType }) {
  return (
    <Mutation
      mutation={SET_USER_LOGIN_TYPE}
      onCompleted={() => {
        hideError();
        history.push("/redirect");
      }}
      onError={() => {
        showError();
      }}
    >
      {(setUserLoginType, { data, loading }) => (
        <ChooseAccountForm
          mutation={setUserLoginType}
          loginType={loginType}
          loading={loading}
          data={data}
        />
      )}
    </Mutation>
  );
}

const ChooseAccount = withRouter(
  connect(
    state => ({
      loginType: getLoginType(state)
    }),
    { showError: setShowError, hideError: setHideError }
  )(MutationComponent)
);

export default ChooseAccount;

MutationComponent.propTypes = {
  showError: PropTypes.func.isRequired,
  hideError: PropTypes.func.isRequired,
  loginType: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired //eslint-disable-line
};
