import React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import RegistrationMobileForm from "./RegistrationMobileForm";
import { setShowError, setHideError } from "../../../store/error/actions";
import {
  setSmsRegistrationClaim,
  setCurrentStep
} from "../../../store/registration/actions";

const SEND_REGISTRATION_SMS = gql`
  mutation sendRegistrationSms($claim: String!, $phoneNumber: String!) {
    sendRegistrationSms(claim: $claim, phoneNumber: $phoneNumber) {
      claim
    }
  }
`;

function MutationComponent({ showError, hideError, setClaim, currentStep }) {
  return (
    <Mutation
      mutation={SEND_REGISTRATION_SMS}
      onCompleted={({ sendRegistrationSms: result }) => {
        setClaim(result.claim);
        currentStep(3);
        hideError();
      }}
      onError={() => showError()}
    >
      {(sendRegistrationSms, { loading }) => (
        <RegistrationMobileForm
          mutation={sendRegistrationSms}
          loading={loading}
        />
      )}
    </Mutation>
  );
}

const RegistrationMobile = withRouter(
  connect(
    null,
    {
      showError: setShowError,
      hideError: setHideError,
      setClaim: setSmsRegistrationClaim,
      currentStep: setCurrentStep
    }
  )(MutationComponent)
);

export default RegistrationMobile;

MutationComponent.propTypes = {
  showError: PropTypes.func.isRequired,
  hideError: PropTypes.func.isRequired,
  setClaim: PropTypes.func.isRequired
};
