import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import styled from "styled-components";
import UserInfoSmsForm from "./UserInfoSmsForm";
import colors from "../Shared/colors";

const SEND_VERIFY_PHONE_NUMBER_SMS = gql`
  mutation verifyPhoneNumber($claim: String!, $smsCode: String!) {
    verifyPhoneNumber(claim: $claim, smsCode: $smsCode)
  }
`;

const ConfirmationMessage = styled.p`
  width: 100%;
  margin: 0;
  font-size: 1em;
  font-weight: bold;
`;

const ConfirmationMessageContainer = styled.div`
  width: 100%;
  padding-bottom: 0.5em;
  border-bottom: solid 1px ${colors.lightGrey};
  margin-bottom: 1.5em;
`;

function UserInfoPhoneNumber() {
  return (
    <Mutation mutation={SEND_VERIFY_PHONE_NUMBER_SMS}>
      {(sendVerifyPhoneNumberSms, { loading, data, error }) => {
        if (data) {
          return (
            <ConfirmationMessageContainer>
              <ConfirmationMessage>Símanúmer staðfest</ConfirmationMessage>
            </ConfirmationMessageContainer>
          );
        }
        return (
          <UserInfoSmsForm
            mutation={sendVerifyPhoneNumberSms}
            loading={loading}
            error={error}
          />
        );
      }}
    </Mutation>
  );
}

export default UserInfoPhoneNumber;
