import { createGlobalStyle } from "styled-components";
import colors from "./Shared/colors";
import VodafoneRgEot from "../fonts/VodafoneRg.eot";
import VodafoneRgWoff from "../fonts/VodafoneRg.woff";
import VodafoneRgTtf from "../fonts/VodafoneRg.ttf";
import VodafoneRgSvg from "../fonts/VodafoneRg.svg";
import VodafoneRgBdEot from "../fonts/VodafoneRgBd.eot";
import VodafoneRgBdWoff from "../fonts/VodafoneRgBd.woff";
import VodafoneRgBdTtf from "../fonts/VodafoneRgBd.ttf";
import VodafoneRgBdSvg from "../fonts/VodafoneRgBd.svg";
import VodafoneExBdEot from "../fonts/VodafoneExBd.eot";
import VodafoneExBdWoff from "../fonts/VodafoneExBd.woff";
import VodafoneExBdTtf from "../fonts/VodafoneExBd.ttf";
import VodafoneExBdSvg from "../fonts/VodafoneExBd.svg";
import VodafoneLtEot from "../fonts/VodafoneLt.eot";
import VodafoneLtWoff from "../fonts/VodafoneLt.woff";
import VodafoneLtTtf from "../fonts/VodafoneLt.ttf";
import VodafoneLtSvg from "../fonts/VodafoneLt.svg";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Vodafone";
    font-style: normal;
    font-weight: normal;
    src: url("${VodafoneRgEot}");
    src: local("?"), url("${VodafoneRgWoff}") format("woff"),
      url("${VodafoneRgTtf}") format("truetype"),
      url("${VodafoneRgSvg}") format("svg");
  }

  @font-face {
    font-family: "Vodafone";
    font-style: normal;
    font-weight: bold;
    src: url("${VodafoneRgBdEot}");
    src: local("?"), url("${VodafoneRgBdWoff}") format("woff"),
      url("${VodafoneRgBdTtf}") format("truetype"),
      url("${VodafoneRgBdSvg}") format("svg");
  }

  @font-face {
    font-family: "Vodafone";
    font-style: normal;
    font-weight: bolder;
    src: url("${VodafoneExBdEot}");
    src: local("?"), url("${VodafoneExBdWoff}") format("woff"),
      url("${VodafoneExBdTtf}") format("truetype"),
      url("${VodafoneExBdSvg}") format("svg");
  }

  @font-face {
    font-family: "Vodafone";
    font-style: normal;
    font-weight: lighter;
    src: url("${VodafoneLtEot}");
    src: local("?"), url("${VodafoneLtWoff}") format("woff"),
      url("${VodafoneLtTtf}") format("truetype"),
      url("${VodafoneLtSvg}") format("svg");
  }

  html {
    box-sizing: border-box;
    font-size: 1em;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }
  
  body {
    font-family: "Vodafone", Helvetica, Arial, sans-serif;
    font-weight: normal;
    line-height: 1.45;
    color: ${colors.darkerGrey};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: ${colors.backgroundGrey};
  }
  
  p {margin-bottom: 1.3em;}
  
  h1, h2, h3, h4 {
    margin: 1.414em 0 0.5em;
    font-weight: inherit;
    line-height: 1.2;
  }
  
  h1 {
    margin-top: 0;
    font-size: 3.998em;
  }
  
  h2 {font-size: 2.827em;}
  
  h3 {font-size: 1.999em;}
  
  h4 {font-size: 1.414em;}
  
  small, .font_small {font-size: 0.707em;}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

:root {
  -moz-tab-size: 4;
  tab-size: 4;
}

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

body {
  margin: 0;
}

hr {
  height: 0;
}

abbr[title] {
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp,
pre {
  font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button,
select { /* 1 */
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
summary {
  display: list-item;
}
`;

export default GlobalStyle;
