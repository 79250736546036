import styled from "styled-components";
import { Link } from "react-router-dom";

export const LabelLinkContainer = styled.div`
  width: 100%;
`;

export const Label = styled.label`
  font-weight: bold;
  margin-bottom: 1em;
`;
export const ForgotPasswordLink = styled(Link)`
  margin-bottom: 1em;
  float: right;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 1px solid black;
  font-family: "Vodafone", Helvetica, Arial, sans-serif;
  color: black !important;
  font-size: 1em;
  &:hover {
    cursor: pointer;
  }
`;
