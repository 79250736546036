import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Spinner } from "../Shared";
import UserStateFetched from "../UserStateFetched/UserStateFetched";
import { setShowError, setHideError } from "../../store/error/actions";

const GET_USER_STATE = gql`
  query getUserState {
    user(claim: "") {
      state
      company
    }
  }
`;

function PrivateRoutes({ location, showError, hideError }) {
  return (
    <Query
      query={GET_USER_STATE}
      onCompleted={() => hideError()}
      onError={() => showError()}
      variables={{ cache: +new Date() }}
    >
      {({ data, loading, error }) => {
        if (loading) return <Spinner />;
        if (error) return <Redirect to="/eid" />;
        if (data) {
          return (
            <UserStateFetched
              state={data.user.state}
              company={data.user.company}
              currentRoute={location.pathname}
            />
          );
        }
      }}
    </Query>
  );
}

export default connect(
  null,
  { showError: setShowError, hideError: setHideError }
)(PrivateRoutes);

PrivateRoutes.propTypes = {
  location: PropTypes.object.isRequired, // eslint-disable-line
  showError: PropTypes.func.isRequired
};
