import React, { Component } from "react";
import showPass from "../../img/show_password.png";
import hidePass from "../../img/hide_password.png";
import {
  Button,
  ButtonGroup,
  Input,
  InputContainer,
  Spinner,
  Form,
  InputGroup,
  InputText,
  InputLabel,
  InputPasswordContainer,
  InputHideShowPassIcon
} from "../Shared";

const NOT_SAME = "Lykilorðin verða að stemma";
const TOO_SHORT = "Lykilorðið verður að vera amk 8 stafir að lengd";

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonActive: false,
      password: "",
      confirmPassword: "",
      inputError: "",
      showPassword: false,
      showConfirmPassword: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.displayInputError = this.displayInputError.bind(this);
    this.checkPasswords = this.checkPasswords.bind(this);
    this.submit = this.submit.bind(this);
    this.showHidePassword = this.showHidePassword.bind(this);
  }

  showHidePassword(e) {
    e.preventDefault();
    let isInputPassword = false;
    if (e.target.id === "passwordIcon") {
      isInputPassword = true;
    }
    this.setState({
      showPassword: isInputPassword
        ? !this.state.showPassword
        : this.state.showPassword,
      showConfirmPassword: !isInputPassword
        ? !this.state.showConfirmPassword
        : this.state.showConfirmPassword
    });
  }

  submit(e, mutation, claim, password, confirmPassword) {
    e.preventDefault();
    const { buttonActive } = this.state;
    if (!buttonActive) {
      return;
    }
    mutation({ variables: { claim, password: confirmPassword } });
  }

  checkPasswords(password, confirmPassword) {
    const isEmpty = password === "" || confirmPassword === "";
    const isSame = password === confirmPassword;

    if (
      isEmpty ||
      !isSame ||
      (password.length < 8 && confirmPassword.length < 8)
    ) {
      if (password.length === confirmPassword.length && !isEmpty) {
        this.displayInputError();
      }
      return false;
    }
    return true;
  }

  displayInputError() {
    const { password, confirmPassword } = this.state;
    if (password !== "" && confirmPassword !== "") {
      if (password !== confirmPassword) {
        this.setState({
          inputError: NOT_SAME
        });
      } else if (password.length < 8 || confirmPassword.length < 8) {
        this.setState({
          inputError: TOO_SHORT
        });
      }
      return;
    }
    this.setState({
      inputError: ""
    });
  }

  handleChange(e) {
    // Clear any current input errors displayed
    this.setState({
      inputError: ""
    });

    const { password, confirmPassword } = this.state;
    let setPassword = false;
    const { value } = e.target;
    if (e.target.id === "password") {
      setPassword = true;
    }

    // First update the password state
    // then activate or disable button accordingly
    this.setState(
      {
        password: setPassword ? value : password,
        confirmPassword: !setPassword ? value : confirmPassword
      },
      () => {
        const isInputValid = this.checkPasswords(
          this.state.password,
          this.state.confirmPassword
        );
        this.setState({
          buttonActive: isInputValid
        });
      }
    );
  }

  render() {
    const {
      buttonActive,
      password,
      confirmPassword,
      inputError,
      showPassword,
      showConfirmPassword
    } = this.state;
    const { mutation, loading, claim } = this.props;

    const isError = inputError !== "";
    return (
      <Form
        onSubmit={e => {
          this.submit(e, mutation, claim, password, confirmPassword);
        }}
      >
        {loading ? <Spinner /> : null}
        <InputText>Vinsamlegast veldu nýtt lykilorð</InputText>
        <InputLabel inputError={isError}>
          {isError ? inputError : "Nýtt lykilorð"}
        </InputLabel>
        <InputContainer width="100">
          <InputGroup direction="column" width="50">
            <InputPasswordContainer width="100">
              <Input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Lykilorð"
                width="95"
                inputError={inputError}
                onChange={e => this.handleChange(e)}
                onBlur={() => this.displayInputError()}
              />
              <InputHideShowPassIcon
                src={showPassword ? hidePass : showPass}
                onClick={e => this.showHidePassword(e)}
                id="passwordIcon"
              />
            </InputPasswordContainer>
          </InputGroup>

          <InputGroup width="50" direction="column">
            <InputPasswordContainer width="100">
              <Input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                placeholder="Staðfesta lykilorð"
                width="100"
                inputError={isError}
                onChange={e => this.handleChange(e)}
                onBlur={() => this.displayInputError()}
              />
              <InputHideShowPassIcon
                src={showConfirmPassword ? hidePass : showPass}
                onClick={e => this.showHidePassword(e)}
                id="confirmPasswordIcon"
              />
            </InputPasswordContainer>
          </InputGroup>
        </InputContainer>
        <ButtonGroup>
          <Button
            type="submit"
            marginRight="0"
            width="39"
            active={buttonActive}
          >
            Breyta
          </Button>
        </ButtonGroup>
      </Form>
    );
  }
}

export default ResetPasswordForm;
