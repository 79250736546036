import jwtDecode from "jwt-decode";

export function hasToken() {
  const token = sessionStorage.getItem("id_token");
  if (!token) {
    return false;
  }

  if (jwtDecode(token).exp < Date.now() / 1000) {
    sessionStorage.removeItem("id_token");
    return false;
  }
  return true;
}
