export const getButtonActive = (state) =>
  !!state.userInfo.email && state.userInfo.password.length >= 8;
export const getTermsAccepted = (state) => state.userInfo.termsAccepted;
export const getEmail = (state) => state.userInfo.email;
export const getPassword = (state) => state.userInfo.password;
export const getPhoneNumber = (state) => state.userInfo.phoneNumber;
export const getSmsCode = (state) => state.userInfo.smsCode;
export const getSmsVerificationClaim = (state) =>
  state.userInfo.smsVerificationClaim;
export const getNeedsVerification = (state) =>
  state.userInfo.needsPhoneVerification;
export const getShowMessage = (state) => state.userInfo.showMessage;
