import styled, { css } from "styled-components";

export const Button = styled.button`
  outline: none;
  background: ${props => (props.active ? "#e60000" : "white")};
  height: ${props => (props.height ? `${props.height}%` : "")};
  color: ${props => (props.active ? "white" : "#e2e2e2")};
  font-size: 1.25em;
  font-weight: bold;
  margin: ${props => (props.margin ? props.margin : 0)};
  margin-right: ${props => (props.marginRight ? props.marginRight : "auto")};
  margin-top: ${props => (props.marginTop ? props.marginTop : "auto")};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "auto")}
  padding: ${props => (props.padding ? props.padding : "0.75em 0.75em")};
  border: ${props => (props.active ? "solid 1px white" : "solid 1px #e2e2e2")};
  font-family: "Vodafone", sans-serif;
  width: ${props => (props.width ? `${props.width}%` : "35%")};
  text-align: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0);
  align-self: ${({ alignSelf }) => alignSelf || "auto"};
  transition: box-shadow 0.3s ease-out, background-color 0.3s ease-out;
  &:hover {
    ${props =>
      props.active &&
      css`
        background-color: rgba(230, 0, 0, 0.85);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
        cursor: pointer;
      `}
  }
  &:active &:focus {
    ${props =>
      props.active &&
      css`
        color: #ffffff;
        outline: none;
      `}
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: ${props => (props.marginTop ? props.marginTop : "0")};
  width: ${props => (props.width ? props.width : "100")}%;
`;
