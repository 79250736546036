import { createStore, combineReducers } from "redux";
import login from "./login/reducers";
import error from "./error/reducers";
import registration from "./registration/reducers";
import userInfo from "./userInfo/reducers";

const ssoApp = combineReducers({
  login,
  error,
  registration,
  userInfo
});

const store = createStore(
  ssoApp,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
