import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import RegistrationSmsForm from "./RegistrationSmsForm";
import { setShowError, setHideError } from "../../../store/error/actions";
import GetUser from "../../GetUser/GetUser";

const VERIFY_REGISTRATION_SMS = gql`
  mutation verifyRegistrationSms($claim: String!, $smsCode: String!) {
    verifyRegistrationSms(claim: $claim, smsCode: $smsCode) {
      claim
    }
  }
`;

function MutationComponent({ showError, hideError, location }) {
  return (
    <Mutation
      mutation={VERIFY_REGISTRATION_SMS}
      onCompleted={() => hideError()}
      onError={() => {
        showError();
      }}
    >
      {(verifyRegistrationSms, { data, loading }) => {
        if (data) {
          return (
            <GetUser
              claim={data.verifyRegistrationSms.claim}
              path={location.pathname}
            />
          );
        }
        return (
          <RegistrationSmsForm
            mutation={verifyRegistrationSms}
            loading={loading}
            data={data}
          />
        );
      }}
    </Mutation>
  );
}

const RegistrationSms = withRouter(
  connect(
    null,
    { showError: setShowError, hideError: setHideError }
  )(MutationComponent)
);

export default RegistrationSms;

MutationComponent.propTypes = {
  showError: PropTypes.func.isRequired,
  hideError: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired // eslint-disable-line
};
