export const SET_EMAIL = "SET_EMAIL";
export const SET_PHONE_NUMBER = "SET_PHONE_NUMBER";
export const SET_PASSWORD = "SET_PASSWORD";
export const TOGGLE_TERMS_ACCEPTED = "SET_TERMS_ACCEPTED";
export const SET_SMS_CODE = "SET_SMS_CODE";
export const SET_SMS_VERIFICATION_CLAIM = "SET_SMS_VERIFICATION_CLAIM";
export const SET_PHONE_VERIFIED = "SET_PHONE_VERIFIED";
export const SET_NEEDS_PHONE_VERIFICATION = "SET_NEEDS_PHONE_VERIFICATION";
export const SET_SHOW_MESSAGE = "SET_SHOW_MESSAGE";

export const setEmail = email => ({
  type: SET_EMAIL,
  email
});

export const setPhoneNumber = phoneNumber => ({
  type: SET_PHONE_NUMBER,
  phoneNumber
});

export const setPassword = password => ({
  type: SET_PASSWORD,
  password
});

export const toggleTermsAccepted = () => ({
  type: TOGGLE_TERMS_ACCEPTED
});

export const setSmsCode = code => ({
  type: SET_SMS_CODE,
  code
});

export const setSmsVerificationClaim = claim => ({
  type: SET_SMS_VERIFICATION_CLAIM,
  claim
});

export const setPhoneVerified = verified => ({
  type: SET_PHONE_VERIFIED,
  verified
});

export const setNeedsPhoneVerification = needsVerification => ({
  type: SET_NEEDS_PHONE_VERIFICATION,
  needsVerification
});

export const setShowMessage = showMessage => ({
  type: SET_SHOW_MESSAGE,
  showMessage
});
