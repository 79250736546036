import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, ButtonGroup, Spinner, Header, Form } from "../Shared";
import UserInfoEmail from "./UserInfoEmail";
import UserInfoPassword from "./UserInfoPassword";
import {
  getButtonActive,
  getNeedsVerification,
  getPhoneNumber,
  getEmail,
  getPassword,
} from "../../store/userInfo/selectors";
import UserInfoPhoneNumber from "./UserInfoPhoneNumber";
import { TermsLink, TermsContainer } from "./styles";

function UserInfoFormComponent({
  loading,
  mutation,
  needsVerification,
  buttonActive,
  selectedEmail,
  selectedPhoneNumber,
  selectedPassword,
}) {
  function submitNewUserInfo(e) {
    e.preventDefault();
    if (!buttonActive) {
      return;
    }
    mutation({
      variables: {
        email: selectedEmail.trim(),
        phoneNumber: selectedPhoneNumber.trim(),
        masterPassword: selectedPassword,
        termsAccepted: true,
      },
    });
  }

  const descriptionText = needsVerification
    ? "Veldu netfang, símanúmer og nýtt lykilorð sem þú vilt nota fyrir sameinaðan aðgang"
    : "Veldu netfang og nýtt lykilorð sem þú vilt nota fyrir sameinaðan aðgang";

  return (
    <Form
      onSubmit={(e) => {
        submitNewUserInfo(e);
      }}
    >
      <Header
        header="Staðfesta notendaupplýsingar"
        description={descriptionText}
      />
      <UserInfoEmail />
      <UserInfoPassword />
      <ButtonGroup marginTop="2em">
        <TermsContainer>
          Með því að staðfesta samþykkir þú{" "}
          <TermsLink
            href="https://vodafone.is/skilmalar-thjonustu/"
            tabIndex="-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            skilmála
          </TermsLink>
        </TermsContainer>
        <Button
          disabled={!buttonActive}
          active={buttonActive && !loading}
          type="submit"
          marginTop="0"
          alignSelf="flex-end"
          marginRight="none"
        >
          {loading ? <Spinner button /> : "Staðfesta"}
        </Button>
      </ButtonGroup>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    buttonActive: getButtonActive(state),
    needsVerification: getNeedsVerification(state),
    selectedPhoneNumber: getPhoneNumber(state),
    selectedEmail: getEmail(state),
    selectedPassword: getPassword(state),
  };
}

const UserInfoForm = connect(mapStateToProps)(UserInfoFormComponent);

export default UserInfoForm;

UserInfoFormComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  mutation: PropTypes.func.isRequired,
  needsVerification: PropTypes.bool.isRequired,
  buttonActive: PropTypes.bool.isRequired,
  selectedPhoneNumber: PropTypes.string.isRequired,
  selectedEmail: PropTypes.string.isRequired,
  selectedPassword: PropTypes.string.isRequired,
};
