import React from "react";
import { Redirect } from "react-router-dom";
import { parse } from "query-string";

export default function Continue() {
  const parsed = parse(window.location.search);
  const origin = decodeURIComponent(parsed.origin);
  window.sessionStorage.clear();
  if (!parsed.origin) {
    window.sessionStorage.setItem("origin", "Mínar síður");
  } else {
    window.sessionStorage.setItem("origin", origin);
  }
  if (parsed.continue) {
    window.sessionStorage.setItem(
      "continueUri",
      decodeURIComponent(parsed.continue)
    );
  }
  if (parsed.redirectUri) {
    window.sessionStorage.setItem(
      "redirectUri",
      decodeURIComponent(parsed.redirectUri)
    );
  }
  if (parsed.aid) {
    window.sessionStorage.setItem("aid", decodeURIComponent(parsed.aid));
  }
  return (
    <Redirect
      to={{
        pathname: "/eid"
      }}
    />
  );
}
