import React, { useState } from "react";
import {
  Button,
  Input,
  Spinner,
  InputLabel,
  Form,
  InputGroup,
  Header
} from "../../Shared";

function RegistrationSsnForm({ loading, mutation }) {
  const [ssn, setSsn] = useState("");
  const [buttonActive, setButtonActive] = useState(false);
  const [inputError, setInputError] = useState({
    error: false,
    message: ""
  });

  function handleInvalidInput(e) {
    e.preventDefault();
    setInputError({
      error: true,
      message: "Vinsamlegast sláðu inn gilda kennitölu"
    });
  }

  function handleChange(e) {
    e.preventDefault();
    setButtonActive(e.target.value.length === 10);
    setSsn(e.target.value);
    setInputError(false);
  }

  function handleErrorState(error) {
    if (error) {
      error.graphQLErrors.forEach(({ extensions }) => {
        switch (extensions.code) {
          case "ALREADY_EXISTS":
            setInputError({
              error: true,
              message: "Notandi nú þegar til"
            });
            break;
          case "RESOURCE_EXHAUSTED":
            setInputError({
              error: true,
              message: "Of margar tilraunir vinsamlegast hinkraðu aðeins"
            });
            break;
          default:
            setInputError({
              error: true,
              message: "Óvænt villa kom upp, vinsamlegast reyndu aftur"
            });
            break;
        }
      });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setButtonActive(false);
    mutation({ variables: { username: ssn } }).catch(error => {
      handleErrorState(error);
      setButtonActive(true);
    });
  }

  return (
    <Form
      onSubmit={e => {
        handleSubmit(e, mutation, ssn);
      }}
    >
      <Header
        header="Fá skjal sent í heimabanka"
        description="Sláðu inn kennitöluna þína og við sendum þér skjal í heimabankann sem birtist undir rafrænum skjölum."
      />
      <InputLabel inputError={inputError.error}>
        {inputError.error ? inputError.message : "Sláðu inn kennitöluna þína"}
      </InputLabel>
      <InputGroup direction="row" width="100">
        <Input
          onChange={e => handleChange(e)}
          onInvalid={e => handleInvalidInput(e)}
          width="60"
          type="text"
          placeholder="Kennitala"
          autoFocus
          minLength="10"
          maxLength="10"
          pattern="^\d{10}$"
        />
        <Button
          active={buttonActive}
          type="submit"
          height="40"
          margin="auto"
          marginRight="0"
        >
          {loading ? <Spinner button /> : "Senda"}
        </Button>
      </InputGroup>
    </Form>
  );
}

export default RegistrationSsnForm;
