import { SHOW_ERROR, HIDE_ERROR } from "./actions";

const initalStateError = {
  message: "",
  showError: false
};

export default function error(state = initalStateError, action) {
  switch (action.type) {
    case SHOW_ERROR:
      return {
        ...state,
        message: action.message,
        showError: action.showError
      };
    case HIDE_ERROR:
      return {
        ...state,
        message: action.message,
        showError: action.showError
      };
    default:
      return state;
  }
}
